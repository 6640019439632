import React from 'react';

import Layout from '../../components/blocks/Layout';
import SEO from '../../components/blocks/SEO';
import PersonalizationHeader from '../../components/slides/features/personalization/PersonalizationHeader';
import MotivateYourAudience from '../../components/slides/features/personalization/MotivateYourAudience';
import AutomaticallyRedirect from '../../components/slides/features/personalization/AutomaticallyRedirect';
import IntegrateDeadlineFunnel from '../../components/slides/features/personalization/IntegrateDeadlineFunnel';

const Personalization = () => (
  <Layout>
    <SEO
      title="Personalization | Deadline Funnel"
      description="Create a personal journey for each of your subscribers, and present unique offers to each person through your emails and landing pages."
    />
    <PersonalizationHeader />
    <MotivateYourAudience />
    <AutomaticallyRedirect />
    <IntegrateDeadlineFunnel />
  </Layout>
);

export default Personalization;