import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './MotivateYourAudience.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';
import Text from '../../../../elements/Text';

const MotivateYourAudience = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/motivate-your-audience.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              texts
            }
          }
        }
      }
    }
  `);

  const { title, texts } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.slideContainer}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            <Title className={styles.title}>{title}</Title>
          </div>
          <div className="row relative-position">
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-xl-end align-items-lg-end align-items-center order-xl-0 order-lg-0 order-1">
              <div className={styles.textAreaModel}>Type your custom text here...</div>
              <div className={styles.buttonModel}>Try it now!</div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-xl-start align-items-lg-start align-items-center order-xl-1 order-lg-1 order-0">
              {
                texts.map(text =>
                  <div className={styles.textContainer}>
                    <Text>{text}</Text>
                  </div>
                )
              }
            </div>
            <Image name="personalization/guy" className={styles.guyImage} />
            <Image name="personalization/plant" className={styles.plantImage} />
          </div>
        </Container>
      </Container>
    </div>
  )

}

export default MotivateYourAudience;