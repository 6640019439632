import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './IntegrateDeadlineFunnel.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';
import BulletedList from '../../../../elements/BulletedList';

const IntegrateDeadlineFunnel = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/integrate-deadline-funnel.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              bullets
            }
          }
        }
      }
    }
  `);

  const { title, bullets } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.slideContainer}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            <div className="col-12">
              <Title className={styles.title}>{title}</Title>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-center order-xl-0 order-lg-0 order-1">
              <Image name="personalization/integrate_df_image" className={styles.image} />
            </div>
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column align-items-xl-start align-items-md-center align-items-lg-start align-items-start order-xl-1 order-lg-1 order-0">
              <div className={styles.textsContainer}>
                <BulletedList list={bullets} color="#00B7D1" type="big" />
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  )

}

export default IntegrateDeadlineFunnel;