import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './PersonalizationHeader.module.css';

import Container from '../../../../blocks/Container';
import BulletedList from '../../../../elements/BulletedList';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';
import Breadcrumbs from '../../../../blocks/Breadcrumbs';

const PersonalizationHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/personalization-header.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              bullets
            }
          }
        }
      }
    }
  `);

  const { title, bullets } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.personalizationHeader}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 d-flex justify-content-xl-end justify-content-lg-end justify-content-center">
              <div className={styles.textsContainer}>
                <Breadcrumbs crumbs={['<a href="/features">Features</a>', 'Personalization']} />
                <Title type="bigger" className="text-left mb-4">
                  {title}
                </Title>
                <BulletedList list={bullets} color="#00B7D1" type="big" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12 d-flex justify-content-xl-start justify-content-lg-start justify-content-center align-items-end">
              <Image name="personalization/personalization_header" className={styles.image} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default PersonalizationHeader;
