import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './AutomaticallyRedirect.module.css';

import Container from '../../../../blocks/Container';
import Title from '../../../../elements/Title';
import Image from '../../../../elements/Image';
import Text from '../../../../elements/Text';

const AutomaticallyRedirect = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/automatically-redirect.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              texts
            }
          }
        }
      }
    }
  `);

  const { title, texts } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.slideContainer}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 offset-xl-1 offset-lg-1 d-flex flex-column align-items-xl-start align-items-lg-start align-items-center p-xl-0 p-lg-0">
              <div className={styles.textsContainer}>
                <Title type="bigger" className={`${styles.title} text-left`}>{title}</Title>
                {
                  texts.map(text =>
                    <div className={styles.textContainer}>
                      <Text>{text}</Text>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-12 d-flex flex-column align-items-xl-start align-items-lg-start align-items-center p-xl-0 p-lg-0">
              <Image name="personalization/automatically_redirect" className={styles.image} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  )

}

export default AutomaticallyRedirect;